import React from "react"

import Layout from "../components/layout"

export default ({ pageContext: { item } }) => (
  <Layout>
    <h2>{item.name}</h2>
    <p>CAD {item.price}</p>
    <p>{item.descriptionMarkdown.descriptionMarkdown}</p>
    <section>
      {item.images.map((image) => <img src={ image.fixed.src} alt={ item.name } />)}
    </section>
  </Layout>
)